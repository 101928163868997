<template>
    <div>
		<!-- Iframe para mostrar el PDF -->
		<iframe ref="pdfFrame" style="width: 100%; height: 100vh; border: none;"></iframe>

		<!-- Contenido del recibo envuelto en un div y oculto para captura -->
		<div class="invoice-wrapper" ref="receiptContent">
			<div class="invoice">
				<div class="invoice-container">
					<div class="invoice-head">
						<div class="invoice-head-top">
							<div class="invoice-head-top-left text-start">
								<img :src="`data:image/jpg;base64,${reporte.imagenBase64}`">
							</div>
							<div class="invoice-head-top-center text-start">
								<ul>
									<li class="text-bold text-blue">{{ reporte.empresa }}</li>
									<li>{{ reporte.direccion }}</li>
									<li>{{ reporte.telefono }}</li>
									<li>{{ reporte.correo }}</li>
									<li>{{ reporte.ubicacion }}</li>
								</ul>
							</div>
							<div class="invoice-head-top-right text-end text-blue">
								<span class="text-bold text-22">{{ reporte.documento }}</span><br>
								<span class="text-bold text-blue text-12">{{ reporte.almacen }}</span><br>
								<span class="text-blue text-12">Usuario: {{ reporte.usuario }}</span>
							</div>
						</div>
						<div class="hr"></div>
						<div class="invoice-head-middle">
							<div class="invoice-head-middle-left text-start">
								<p><span class="text-bold text-blue">Fecha de Ajuste</span>: {{ reporte.fechaventa }} {{ reporte.horaventa }}</p>
							</div>
							<div class="invoice-head-middle-right text-end">
								<p><span class="text-bold text-blue">Nota No: </span>{{ reporte.nroventa }}</p>
							</div>
						</div>
						<div class="hr"></div>
						<div class="invoice-head-bottom">
							<div class="invoice-head-bottom-left">
								<ul>
									<li><span class="text-bold text-blue">Tipo Ajuste:</span> {{ reporte.tipodeventa }}</li>
								</ul>
							</div>
							<div class="invoice-head-bottom-right">
								<ul class="text-end">
									<li><span class="text-bold text-blue">Moneda:</span> {{ reporte.nombreMoneda }}</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="overflow-view">
						<div class="invoice-body">
							<table>
								<thead>
									<tr>
										<td class="text-bold">Nro</td>
										<td class="text-bold">Código</td>
										<td class="text-bold">Descripción</td>
										<td class="text-bold text-center">Cantidad</td>
										<td class="text-bold text-center">P.Compra</td>
										<td class="text-bold text-end">Subtotal</td>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(item, index) in reporte.detalle" :key="index">
										<td>{{ index + 1 }}</td>
										<td>{{ item.codigo }}</td>
										<td>{{ item.nombre }} {{ item.medida }}</td>
										<td class="text-center">{{ item.cantidad }}</td>
										<td class="text-center">{{ item.precioCompra }}</td>
										<td class="text-end">{{ item.subtotal }}</td>
									</tr>
								</tbody>
							</table>
							<div class="invoice-body-bottom">
								<div class="invoice-body-info-item border-bottom">
									<div class="info-item-td text-end text-bold text-blue text-12">Total {{ reporte.simbolo }}:</div>
									<div class="info-item-td text-end text-14">{{ reporte.montototal }}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<p><span class="text-bold text-center text-blue text-12">Son:&nbsp;</span><span class="text-12" style="color:#000000;">{{ reporte.montoliteral }}</span></p>
				<div class="invoice-foot text-center">
					<p v-if="reporte.observacion"><span class="text-bold text-center text-blue ">Observación:&nbsp;</span>{{ reporte.observacion }}</p>
					<span class="text-center text-blue text-11 text-center">Reporte generado por www.efectivo.io <br> Fecha Reporte {{ reporte.fechareporte }}</span>
				</div>
			</div>
		</div>

		<div v-if="spinnerloading == true" class="col-md-12" style="position: fixed; top: 0; right: 0; bottom: 0; left: 0; background: rgba(0, 0, 0, 1); z-index: 1055;">
			<div style="margin-top: 250px;">
				<vue-simple-spinner :line-size="10" size="massive" text-fg-color="#f3f3f3" message="Procesando Información..."></vue-simple-spinner>
			</div>
		</div>
    </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import * as utils from '@/utils/utils'
  import jsPDF from 'jspdf'
  import html2canvas from 'html2canvas'

  export default {

      props: {
          ajusteingresoID: { type: String, required: true }
      },

      data () {
          return {
			 spinnerloading: false
          }
      }, 

      computed: {
          ...mapGetters('auth', ['user']),
          ...mapGetters('ajusteingreso', ['reporte'])
      },

      methods: {
          ...mapActions('ajusteingreso', ['getReporteAjusteIngreso']),

		  imprimir () {
			 this.spinnerloading = true
			 this.getReporteAjusteIngreso(this.ajusteingresoID)
			 .then(response => {
                this.generatePDF()
				setTimeout(function () {
					this.spinnerloading = false
					const receiptContent = this.$refs.receiptContent
					const pdfFrame = this.$refs.pdfFrame
					receiptContent.style.display = 'none'
					setTimeout(() => {
						pdfFrame.contentWindow.print()
					}, 500)
				}.bind(this), 1000)
             }).catch(error => {
                utils.openNotificationWithIcon('error', 'Error al enviar la Informacion', utils.showErrors(error.response.data), 'topRight')
             })
		  },

		  async generatePDF () {
			const receiptContent = this.$refs.receiptContent
			  const pdfFrame = this.$refs.pdfFrame

			const canvas = await html2canvas(receiptContent, {
				scale: 2,
				useCORS: true,
				logging: false,
				allowTaint: true,
				letterRendering: true,
				backgroundColor: null,
				dpi: 192
			})

			const imgData = canvas.toDataURL('image/png')

			const pdf = new jsPDF({ // eslint-disable-line new-cap
				orientation: 'portrait',
				unit: 'mm',
				format: 'letter',
				compress: true // Optimización de compresión para reducir el tamaño del PDF
			})

			const imgProps = pdf.getImageProperties(imgData)
			const pdfWidth = pdf.internal.pageSize.getWidth()
			const pdfHeight = pdf.internal.pageSize.getHeight()
			const pdfHeightOther = (imgProps.height * pdfWidth) / imgProps.width // Una sola pagina
			const canvasWidth = canvas.width
			const canvasHeight = canvas.height
			const ratio = canvasWidth / pdfWidth
			const pageHeight = pdfHeight * ratio
			let pageNumber = 0
			const totalPages = Math.ceil(canvasHeight / pageHeight)
			
			if (totalPages > 1) {
				while (pageNumber * pageHeight < canvasHeight) {
					if (pageNumber > 0) {
						pdf.addPage()
					}

					const startY = pageNumber * pageHeight
					const canvasPage = document.createElement('canvas')
					const ctx = canvasPage.getContext('2d')

					canvasPage.width = canvasWidth
					canvasPage.height = pageHeight

					ctx.drawImage(canvas, 0, startY, canvasWidth, pageHeight, 0, 0, canvasWidth, pageHeight)

					const imgDataPage = canvasPage.toDataURL('image/png')
					pdf.addImage(imgDataPage, 'PNG', 0, 10, pdfWidth, pdfHeight - 25)

					// Añadir número de página
					pdf.setFontSize(10)
					pdf.text(`Página ${pageNumber + 1} de ${totalPages}`, pdfWidth / 2, pdfHeight - 10, { align: 'center' })

					pageNumber++
				}
			} else {
				pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeightOther)
			}
			const pdfDataUri = pdf.output('bloburi')
			pdfFrame.src = pdfDataUri
		  }
      },

	  mounted () {
		  this.imprimir()
	  }
  }
</script>

<style scoped>
	*,
	*::after,
	*::before{
		padding: 0;
		margin: 0;
		box-sizing: border-box;
	}

	ul{
		list-style-type: none;
	}

	ul li{
		margin: 2px 0;
	}

	/* text colors */
	.text-dark{
		color:#000000;
	}
	.text-blue{
		color: #000000;
	}
	.text-end{
		text-align: right;
	}
	.text-center{
		text-align: center;
	}
	.text-start{
		text-align: left;
	}
	.text-bold{
		font-weight: 700;
	}
	.text-11 {
		font-size: 11px;
	}
	.text-12 {
		font-size: 12px;
	}
	.text-14 {
		font-size: 14px;
	}
	.text-15 {
		font-size: 15px;
	}
	.text-22 {
		font-size: 22px;
	}
	.text-24 {
		font-size: 24px;
	}
	.text-28 {
		font-size: 28px;
	}

	/* hr line */
	.hr{
		height: 1px;
		background-color: rgba(0, 0, 0, 0.1);
	}
	/* border-bottom */
	.border-bottom{
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	}

	body{
		font-family: 'Poppins', sans-serif;
		color:#000000;
		font-size: 14px;
	}

	.invoice-wrapper{
		min-height: 100vh;
		max-width: 100%;
		  overflow: hidden;
	}

	.invoice{
		max-width: 100%;
		margin-right: auto;
		margin-left: auto;
		background-color: #fff;
		padding: 0px;
		border-radius: 5px;
		min-height: 920px;
	}

	.invoice-head-top-left img{
		width: 130px;
	}

	.invoice-head-top-center {
		margin-left: -80px;
	}

	.invoice-head-top-right h3{
		font-weight: 500;
		font-size: 30px;
		color: #000000;
	}

	.invoice-head-middle, .invoice-head-bottom{
		padding: 0px 0;
	}

	.invoice-body{
		border-radius: 4px;
		overflow: hidden;
	}

	.invoice-body table{
		border-collapse: collapse;
		border-radius: 4px;
		width: 100%;
	}

	.invoice-body table td, .invoice-body table th{
		padding: 5px;
	}

	.invoice-body table tr{
		border: 1px solid rgba(0, 0, 0, 0.1);
		font-size: 12px;
	}
	
	.invoice-body table thead{
		color: #fff;
		background-color: rgba(0, 0, 0, 0.842);
	}

	.invoice-body table tbody tr{
		color:#000000;
	}

	.invoice-body-bottom{
		width: 28%;
		margin-top: -1px;
		margin-left: auto;
		border: 1px solid rgba(0, 0, 0, 0.1);
	}

	.invoice-body-info-item{
		display: grid;
		grid-template-columns: 60% 40%;
	}

	.invoice-body-info-item .info-item-td{
		padding: 3px;
		color:#000000;
	}

	.invoice-foot{
		padding: 10px 0;
	}

	.invoice-foot p{
		font-size: 12px;
	}

	.invoice-head-top {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		padding-bottom: 5px;
	}

	.invoice-head-middle, .invoice-head-bottom{
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		padding-bottom: 5px;
	}

	@media screen and (max-width: 992px){
		.invoice{
			padding: 40px;
		}
	}

	@media screen and (max-width: 576px){
		.invoice-head-top, .invoice-head-middle, .invoice-head-bottom{
			grid-template-columns: repeat(1, 1fr);
		}
		.invoice-head-bottom-right{
			margin-top: 12px;
			margin-bottom: 12px;
		}
		.invoice *{
			text-align: left;
		}
		.invoice{
			padding: 28px;
		}
	}

	.overflow-view{
		overflow-x: none;
	}
	.invoice-body{
		min-width: 100%;
	}
</style>